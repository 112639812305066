import AppContext from 'context/AppContext'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select'

import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'

import getUserMappingAndDashboardList from '../use-cases/getUserMappingAndDashboardList'
import LoadingSpinner from 'components/common/LoadingSpinner'
import getQuickSightURL from '../use-cases/getQuickSightURL'
import getThemePalette from '../../helpers/getThemePalette'

const embeddingContext = await createEmbeddingContext()

const BusinessIntelligence = () => {
  const { repoFactory, selectedYear, theme } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)

  const [url, setUrl] = useState()
  const [dashboardList, setDashboardList] = useState([])

  const [selectedDashboard, setSelectedDashboard] = useState()
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState()

  let embeddedDashboard

  useEffect(() => {
    setIsLoading(true)
    getUserMappingAndDashboardList(
      {
        token: localStorage.getItem('authToken'),
        year: selectedYear
      },
      {
        biRepo: repoFactory.biRepo(),
        observer: {
          errorReceivingData: () => {
            setIsLoading(false)
          },
          receiveData: ({ qsDashboardObj, elementAccountList }) => {
            setIsLoading(false)
            setDashboardList(
              qsDashboardObj.dashboardList
                .filter((item) => item?.type !== 'Topic')
                .map((item) => ({
                  value: item.value,
                  label: item.name
                }))
            )
          }
        }
      }
    )
  }, [repoFactory, selectedYear])

  const getUrl = () => {
    if (selectedDashboard) {
      setIsLoading(true)
      setUrl(null)
      const accountIdList = []
      getQuickSightURL(
        {
          accountIdList,
          dashboardId: selectedDashboard.value
        },
        {
          biRepo: repoFactory.biRepo(),
          observer: {
            errorGettingUrl: (error) => {
              setIsLoading(false)
            },
            receiveQuickSightUrl: (response) => {
              setUrl(response.EmbedUrl)
              setIsLoading(false)
            }
          }
        }
      )
    }
  }

  useEffect(() => {
    if (url) {
      if (embeddedDashboardExperience) {
        document
          .getElementById('experience-container')
          .removeChild(document.getElementById('experience-container').lastChild)
        setEmbeddedDashboardExperience(null)
      }
      updateDashboard(url, theme)
    }
  }, [url])

  const updateDashboard = useCallback(
    async (url) => {
      try {
        const frameOptions = {
          url,
          container: '#experience-container',
          height: '800px',
          width: '100%',
          resizeHeightOnSizeChangedEvent: true,
          onChange: (changeEvent, metadata) => {
            switch (changeEvent.eventName) {
              case 'FRAME_MOUNTED': {
                console.log('Do something when the experience frame is mounted.')
                break
              }
              case 'FRAME_LOADED': {
                console.log('Do something when the experience frame is loaded.')

                break
              }
            }
          }
        }

        const contentOptions = {
          locale: 'en-US',
          toolbarOptions: {
            export: true,
            undoRedo: true,
            reset: true
          },
          attributionOptions: {
            overlayContent: false
          },
          themeOptions: {
            themeOverride: getThemePalette(theme)
          },
          onMessage: async (messageEvent, experienceMetadata) => {
            switch (messageEvent.eventName) {
              case 'CONTENT_LOADED': {
                console.log('All visuals are loaded. The title of the document:', messageEvent.message.title)
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
              case 'ERROR_OCCURRED': {
                console.log(
                  'Error occurred while rendering the experience. Error code:',
                  messageEvent.message.errorCode
                )
                setEmbeddedDashboardExperience(embeddedDashboard)
                break
              }
              case 'PARAMETERS_CHANGED': {
                console.log('Parameters changed. Changed parameters:', messageEvent.message.changedParameters)
                break
              }
              case 'SELECTED_SHEET_CHANGED': {
                console.log('Selected sheet changed. Selected sheet:', messageEvent.message.selectedSheet)
                break
              }
              case 'SIZE_CHANGED': {
                console.log('Size changed. New dimensions:', messageEvent.message)
                break
              }
              case 'MODAL_OPENED': {
                window.scrollTo({
                  top: 0 // iframe top position
                })
                break
              }
            }
          }
        }

        embeddedDashboard = await embeddingContext.embedDashboard(frameOptions, contentOptions)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
    [url, setEmbeddedDashboardExperience]
  )

  useEffect(() => {
    if (embeddedDashboardExperience) {
      embeddedDashboardExperience
        .setThemeOverride(getThemePalette(theme))
        .then((response) => {})
        .catch((error) => {
          console.error(error)
        })
    }
  }, [theme, embeddedDashboardExperience])

  return (
    <div className="business-inteligence">
      {isLoading && <LoadingSpinner />}
      <Card>
        <Card.Body>
          <Row>
            <label>Dashboard</label>
            <Col lg="3">
              <Select
                options={dashboardList}
                classNamePrefix="select"
                onChange={(option) => setSelectedDashboard(option)}
              />
            </Col>
            <Col>
              <Button onClick={getUrl} disabled={!selectedDashboard}>
                Load
              </Button>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col>
              <div id="experience-container"></div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}
export default BusinessIntelligence
