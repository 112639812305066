function CommonRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.getSorElements = (selectedYear, { success, failure }) => {
    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: `query GetSorElements($year: Int!) {
                      getSorListByYear(year: $year) {
                        data {
                          element1List {
                            id
                            value
                          }
                          element2List {
                            id
                            value
                            element2Map
                            element1Id
                          }
                          element3List {
                            id
                            value
                            element3Map
                            element2Id
                            element2Map
                          }
                          element4List {
                            id
                            value
                            element4Map
                            element3Id
                            element3Map
                          }
                        }
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                      sorMapping(year: $year) {
                        element1Name
                        element2Name
                        element3Name
                        element4Name
                        id
                      }
                      getLastRunCur {
                        lastRunTime
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`,
          variables: {
            year: selectedYear
          }
        }
      },
      {
        success: (response) => {
          const { data, errors } = response.data
          if (errors) {
            failure(errors)
            return
          }
          success(data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.getSorMapping = (selectedYear, { success, failure }) => {
    const query = `
      query MyQuery($year: Int!) {
        getSorMapping(year: $year) {
          data {
            availableYears
            currentYearMapping {
              element1
              element2
              element3
              element4
            }
            status
          }
          error {
            code
            message
            statusCode
          }
        }
      }
    `

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query: query,
          variables: {
            year: selectedYear
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }
}

export default CommonRepo
