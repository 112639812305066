const uploadSORJson = async ({ token, effectiveYear, records, elementKeys }, { sorRepo, observer }) => {
  try {
    sorRepo.updateSORData(
      {
        token,
        effectiveYear,
        records,
        elementKeys
      },
      {
        success: (response) => {
          const { updateOrInsertSORData } = response.data.data
          observer.success({
            updates: updateOrInsertSORData.updates,
            deletions: updateOrInsertSORData.deletions,
            insertions: updateOrInsertSORData.insertions,
            requiresApproval: updateOrInsertSORData.requiresApproval,
            elementKeys: updateOrInsertSORData.elementKeys
          })
        },
        failure: (error) => {
          observer.error(error)
        }
      }
    )
  } catch (error) {
    observer.error(error)
  }
}

export default uploadSORJson
