const listSORData = async ({ token, effectiveYear }, { sorRepo, observer }) => {
  try {
    sorRepo.listSORData(
      {
        token,
        effectiveYear
      },
      {
        success: (response) => {
          const { listSORData } = response.data.data
          observer.success(listSORData)
        },
        failure: (error) => {
          observer.error(error)
        }
      }
    )
  } catch (error) {
    observer.error(error)
  }
}

export default listSORData
