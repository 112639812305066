import { useFormik } from 'formik'
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { Modal } from 'react-bootstrap'
import { Fragment, useEffect, useState } from 'react'
import HierarchyForm from '../HierarchyForm'
import uploadSORJson from '../../use-cases/upload-sor-json-use-case'
import { toast } from 'react-toastify'
import getEditorColumns from './EditorColumns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

const validate = (values) => {
  const errors = {}
  const { budgets } = values

  if (budgets.some((budget) => !budget.element1 || !budget.element2 || !budget.element3 || !budget.element4)) {
    errors.budgets = 'Please fill all hierarchy fields'
  }

  if (budgets.some((budget) => !budget.amount || budget.amount < 0)) {
    errors.amount = 'Please enter a valid budget amount'
  }

  return errors
}

const defaultElementKeys = ['Business Unit', 'Department', 'Portfolio', 'Product']

function Editor({ repoFactory, setIsLoading, budgetData, elementKeysData, setBudgetData, selectedYear, isNew }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [elementKeys, setElementKeys] = useState(elementKeysData.length > 4 ? elementKeysData : defaultElementKeys)
  const [sorting, setSorting] = useState([])

  useEffect(() => {
    if (isNew) {
      setElementKeys(defaultElementKeys)
      formik.setValues({ budgets: [{ ...newBudget }] })
    }
  }, [isNew])

  const newBudget = {
    element1: '',
    element2: '',
    element3: '',
    element4: '',
    startDate: `${selectedYear}-01-01`,
    endDate: `${selectedYear}-12-31`,
    isDeleted: false,
    amount: 0
  }

  const formik = useFormik({
    initialValues: {
      budgets: budgetData || []
    },
    validate,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {}
  })

  const save = async () => {
    await formik.submitForm()
    if (formik.isValid) {
      const { values } = formik

      setIsLoading(true)

      const records = values.budgets.map((budget) => ({
        element1: budget.element1,
        element2: budget.element2,
        element3: budget.element3,
        element4: budget.element4,
        amount: budget.amount,
        startDate: budget.startDate,
        endDate: budget.endDate
      }))

      await uploadSORJson(
        {
          token: localStorage.getItem('authToken'),
          effectiveYear: selectedYear,
          records,
          elementKeys
        },
        {
          sorRepo: repoFactory.sorRepo(),
          observer: {
            error: (e) => {
              setIsLoading(false)
              toast.error('Failed to save changes')
            },
            success: (result) => {
              setIsLoading(false)
              setBudgetData(values.budgets)
              toast.success('Changes saved successfully')
            }
          }
        }
      )
    } else {
      const errorMessage = Object.values(formik.errors)[0]
      toast.warning(errorMessage)
    }
  }

  const add = () => {
    formik.setValues({ budgets: [...formik.values.budgets, { ...newBudget }] })
  }

  const remove = (index) => {
    formik.setValues({ budgets: formik.values.budgets.filter((_, i) => i !== index) })
  }

  const table = useReactTable({
    data: formik.values.budgets,
    columns: getEditorColumns(elementKeys, formik, add, remove),
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  })

  return (
    <div>
      <button type="button" className="btn btn-primary btn-sm mb-3" onClick={() => setIsModalOpen(true)}>
        Define Hierarchy
      </button>
      <div className="mb-3">
        <table className="table table-bordered table-editable table-sticky-header">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="text-center">
        <button type="button" className="btn btn-primary btn-sm" onClick={save} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="md">
        <Modal.Body>
          <h5 className="mb-3">Define Hierarchy</h5>
          <hr />
          <HierarchyForm setIsModalOpen={setIsModalOpen} elementKeys={elementKeys} setElementKeys={setElementKeys} />
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default Editor
