function SorRepo({ GRAPHQL_URL, GRAPHQL_API_KEY, httpClient }) {
  this.uploadSOR = ({ token, fileName, fileType, fileContent }, { success, failure }) => {
    const query = `mutation ($token: String!, $file: File!) {
                      uploadSOR(sorUpload: {file: $file, token: $token}) {
                        status
                        error {
                          code
                          message
                          statusCode
                        }
                      }
                    }`

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY
        },
        data: {
          query,
          variables: {
            token,
            file: {
              fileName,
              fileType,
              fileContent
            }
          }
        }
      },
      {
        success: (response) => {
          success(response.data.data)
        },
        failure: (error) => failure(error)
      }
    )
  }

  this.updateSORData = ({ token, effectiveYear, records, elementKeys }, { success, failure }) => {
    const mutation = `
      mutation UpdateSORData($input: UpdateSORDataInput!) {
        updateOrInsertSORData(input: $input) {
          elementKeys
          updates {
            element1
            element2
            element3
            element4
            amount
            startDate
            endDate
          }
          deletions {
            element1
            element2
            element3
            element4
            amount
            startDate
            endDate
          }
          insertions {
            element1
            element2
            element3
            element4
            amount
            startDate
            endDate
          }
          requiresApproval
        }
      }
    `

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        timeout: 30000,
        headers: {
          'x-api-key': GRAPHQL_API_KEY,
          Authorization: `Bearer ${token}`
        },
        data: {
          query: mutation,
          variables: {
            input: {
              effectiveYear,
              records,
              elementKeys
            }
          }
        }
      },
      {
        success,
        failure
      }
    )
  }

  this.listSORData = ({ token, effectiveYear }, { success, failure }) => {
    const query = `
      query listSORData($year: Int!) {
        listSORData(year: $year) {
            elementKeys
            data {
                id
                element1
                element2
                element3
                element4
                amount
                startDate
                endDate
                isDeleted
                updatedAt
            }
        }
      }
    `

    httpClient.request(
      {
        method: 'POST',
        url: GRAPHQL_URL,
        headers: {
          'x-api-key': GRAPHQL_API_KEY,
          Authorization: `Bearer ${token}`
        },
        data: {
          query,
          variables: {
            year: effectiveYear
          }
        }
      },
      {
        success,
        failure
      }
    )
  }
}

export default SorRepo
