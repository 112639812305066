const getSorElements = (selectedYear, { observer, commonRepo }) => {
  commonRepo.getSorElements(selectedYear, {
    success: (sorElements) => {
      const { getSorListByYear, sorMapping, getLastRunCur } = sorElements
      const { error: sorError, data: sorData } = getSorListByYear
      const { error: lastRunError, lastRunTime } = getLastRunCur

      if (sorError || lastRunError) {
        observer.errorGettingData()
        return
      }

      observer.elementsReceived({
        elementList1: sorData.element1List,
        elementList2: sorData.element2List,
        elementList3: sorData.element3List,
        elementList4: sorData.element4List
      })

      observer.receiveLastRunTime(lastRunTime)
    },
    failure: (errors) => {
      console.log('Error getting sor elements', errors)
      observer.errorGettingData('Error getting sor elements')
    }
  })
}

export default getSorElements
