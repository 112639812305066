import React, { Fragment, useEffect, useState } from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import getPaginationControls from 'components/common/Utils/getPaginationControls'

const columnHelper = createColumnHelper()

const UsersWithAccess = ({ usersWithBudget, setUserForMapping, sorElementMapping }) => {
  const [data, setData] = useState([])
  const [filteredItems, setFilteredItems] = useState(usersWithBudget)
  const [searchText, setSearchText] = useState('')
  const [sorting, setSorting] = useState([])

  const columns = [
    columnHelper.accessor('username', {
      header: () => 'Username'
    }),
    columnHelper.accessor('reference', {
      header: () => 'Reference'
    }),
    columnHelper.accessor('accessLevel', {
      header: () => 'Access Level'
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            <button className="btn btn-sm btn-remap" onClick={() => setUserForMapping(row.original)}>
              Remap
            </button>

            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail "
            >
              Details {row.getIsExpanded() ? <FontAwesomeIcon icon={faChevronDown} /> : ' '}
            </button>
          </div>
        )
      }
    })
  ]

  useEffect(() => {
    const items = filteredItems.map((item) => {
      const latestAccess = item.mappings.find((item) => item.isCurrent === null || item.isCurrent === true)
      return {
        id: item.id,
        username: `${item.Username} (${latestAccess.access.length})`,
        reference: latestAccess.reference,
        accessLevel: sorElementMapping[`element${item.accessLevel}`],
        access: item.elements,
        rawRowData: item
      }
    })
    setData(items)
  }, [filteredItems, sorElementMapping])

  useEffect(() => {
    if (searchText) {
      const trimmedSearchText = searchText.trim()
      const filteredItems = usersWithBudget.filter((item) => {
        if (item.Username.toLowerCase().indexOf(trimmedSearchText.toLowerCase()) > -1) {
          return true
        }

        const { mappings } = item

        for (const mapping of mappings) {
          const { access } = mapping
          for (const allocation of access) {
            const { element1Id, element2Id, element3Id, element4Id } = allocation
            if (
              (element1Id && element1Id.toLowerCase().indexOf(trimmedSearchText.toLowerCase()) > -1) ||
              (element2Id && element2Id.toLowerCase().indexOf(trimmedSearchText.toLowerCase()) > -1) ||
              (element3Id && element3Id.toLowerCase().indexOf(trimmedSearchText.toLowerCase()) > -1) ||
              (element4Id && element4Id.toLowerCase().indexOf(trimmedSearchText.toLowerCase()) > -1)
            ) {
              return true
            }
          }
        }
        return false
      })

      setFilteredItems(filteredItems)
    } else {
      setFilteredItems(usersWithBudget)
    }
  }, [searchText, usersWithBudget])

  const onSearchKey = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  return (
    <div className="simple-card">
      <h2>User Scope</h2>
      <Row className="mb-2">
        <Col md={4}>
          <input
            type="text"
            value={searchText}
            className="form-control"
            placeholder="Search by Username or Financial Scope"
            onChange={onSearchKey}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <p className="mb-1 text-sm text-muted">Total users: {data.length}</p>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr className="expanded-row">
                    <td colSpan={row.getVisibleCells().length}>
                      <SubComponentTables data={row.original.rawRowData} sorElementMapping={sorElementMapping} />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginationControls(table)}
    </div>
  )
}

const SubComponentTables = ({ data, sorElementMapping }) => {
  const latestAccess = data.mappings.find((item) => item.isCurrent === null || item.isCurrent === true)

  const componentData = latestAccess.access.map((item) => {
    return {
      businessUnit: item.element1Id,
      department: item.element2Id,
      portfolio: item.element3Id,
      product: item.element4Id
    }
  })

  const columns = [
    columnHelper.accessor('businessUnit', {
      header: () => sorElementMapping.element1
    }),
    columnHelper.accessor('department', {
      header: () => sorElementMapping.element2
    }),
    columnHelper.accessor('portfolio', {
      header: () => sorElementMapping.element3
    }),
    columnHelper.accessor('product', {
      header: () => sorElementMapping.element4
    })
  ]

  return <DataTable columns={columns} data={componentData} updateDate={latestAccess.insertedDate} />
}

const DataTable = ({ columns, data, className, updateDate }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })
  return (
    <div className="table-responsive">
      <table className={`table ${className}`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {updateDate && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className="updated-at">
                  Updated <span>{moment.utc(updateDate).local().format('MM-DD-YYYY hh:mm:ss A')}</span>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  )
}

export default UsersWithAccess
