import { useState } from 'react'

function HierarchyForm({ setIsModalOpen, setElementKeys, elementKeys }) {
  const [element1, setElement1] = useState(elementKeys[0])
  const [element2, setElement2] = useState(elementKeys[1])
  const [element3, setElement3] = useState(elementKeys[2])
  const [element4, setElement4] = useState(elementKeys[3])

  return (
    <div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 1 <div class="form-text">Business Unit</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" value={element1} onChange={(e) => setElement1(e.target.value)} />
        </div>
      </div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 2 <div class="form-text">Department</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" value={element2} onChange={(e) => setElement2(e.target.value)} />
        </div>
      </div>
      <div className="mb-3 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 3 <div class="form-text">Portfolio</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" value={element3} onChange={(e) => setElement3(e.target.value)} />
        </div>
      </div>
      <div className="mb-4 row align-items-center">
        <label for="staticEmail" className="col-sm-3 col-form-label">
          Element 4 <div class="form-text">Project</div>
        </label>
        <div className="col-sm-9">
          <input type="text" className="form-control" value={element4} onChange={(e) => setElement4(e.target.value)} />
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => {
            setElementKeys([element1, element2, element3, element4, element4])
            setIsModalOpen(false)
          }}
        >
          Save
        </button>
        <button type="button" className="btn btn-secondary btn-sm" onClick={() => setIsModalOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  )
}
export default HierarchyForm
