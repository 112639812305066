import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import axios from 'axios'
import HttpClient from './http_client'
import RepoFactory from './repo_factory'
import { BrowserRouter } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import English from './locale/locale-en.json'
import RolesMap from 'config/RolesMap'
import Roles from 'config/Roles'
import { Alert } from 'react-bootstrap'

const _axios = {
  all: axios.all,
  request: axios.request,
  retry: axios,
  interceptors: axios.interceptors
}

const httpClientConfig = {
  timeout: 10000
}

const httpClient = new HttpClient({
  axiosClient: _axios,
  config: httpClientConfig
})

function Index() {
  const locale = navigator.language
  let lang
  if (locale === 'en') {
    lang = English
  } else {
    lang = English
  }

  const [repoFactory, setRepoFactory] = useState()
  const [accountMappingLimit, setAccountMappingLimit] = useState(25)
  const [isQEnabled, setISQEnabled] = useState(false)
  const [config, setConfig] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetch('/config.json', {
      headers: {
        'Cache-Control': 'no-cache'
      }
    })
      .then((response) => response.json())
      .then((config) => {
        setError(false)
        setConfig(config)
      })
      .catch((error) => {
        setError(true)
        console.error('Failed to load configuration:', error)
      })
  }, [])

  useEffect(() => {
    if (config) {
      setRepoFactory(
        new RepoFactory({
          httpClient,
          httpClientConfig,
          GRAPHQL_URL: config.REACT_APP_GRAPHQL_URL,
          GRAPHQL_API_KEY: config.REACT_APP_GRAPHQL_API_KEY
        })
      )
    }
  }, [config, setRepoFactory, RepoFactory, httpClient, httpClientConfig])

  useEffect(() => {
    if (config) {
      RolesMap[Roles.ADMINS] = config.systemAdmins || RolesMap[Roles.ADMINS]
      RolesMap[Roles.FINANCIAL_ADMINS] = config.financialAdmins || RolesMap[Roles.FINANCIAL_ADMINS]
      RolesMap[Roles.BU_MANAGERS] = config.buManagers || RolesMap[Roles.BU_MANAGERS]
      RolesMap[Roles.DEPARTMENT_MANAGERS] = config.departmentManagers || RolesMap[Roles.DEPARTMENT_MANAGERS]
      RolesMap[Roles.PORTFOLIO_MANAGERS] = config.portfolioManagers || RolesMap[Roles.PORTFOLIO_MANAGERS]
      RolesMap[Roles.PRODUCT_MANAGERS] = config.productManage || RolesMap[Roles.PRODUCT_MANAGERS]
    }
  }, [config, RolesMap])

  useEffect(() => {
    if (config?.accountMappingLimit) {
      setAccountMappingLimit(Number(config.accountMappingLimit))
    }

    if (config?.isQEnabled) {
      setISQEnabled(config.isQEnabled === 'true' || config.isQEnabled === true)
    }
  }, [config, setAccountMappingLimit, setISQEnabled])

  return (
    <React.StrictMode>
      <BrowserRouter>
        <IntlProvider locale={locale} messages={lang}>
          {!error ? (
            repoFactory && (
              <App
                repoFactory={repoFactory}
                location={window.location}
                accountMappingLimit={accountMappingLimit}
                isQEnabled={isQEnabled}
                version={config.version}
                packagingType={config.packagingType}
              />
            )
          ) : (
            <Alert variant="danger">Error loading config</Alert>
          )}
        </IntlProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}

ReactDOM.render(<Index />, document.getElementById('cloudscal3-web-root'))

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
