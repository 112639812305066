import { createColumnHelper } from '@tanstack/react-table'
import Select from 'react-select'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'

const columnHelper = createColumnHelper()

const getResourceColumns = (filters = [], workloads = [], sorElementMapping, isReadOnly) => {
  const workloadOptions = workloads.map((workload) => ({ value: workload, label: workload }))
  return [
    ...(!isReadOnly
      ? [
          columnHelper.accessor((row) => row, {
            id: 'select',
            header: ({ table }) => {
              return (
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={table.getIsAllRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              )
            },
            cell: ({ row }) => {
              const { elementMap } = row.original.claimedStatus || {}

              if (elementMap) {
                const isOwner = (elementMap.element1Id =
                  filters.element1 &&
                  elementMap.element2Id === filters.element2 &&
                  elementMap.element3Id === filters.element3 &&
                  elementMap.element4Id === filters.element4)

                if (!isOwner) {
                  return <></>
                }
              }

              return (
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={row.getIsSelected()}
                  onChange={row.getToggleSelectedHandler()}
                />
              )
            },
            enableSorting: false
          })
        ]
      : []),
    columnHelper.accessor((row) => row, {
      id: 'status',
      header: () => 'Status',
      cell: ({ row }) => {
        const { claimedBy, elementMap, startDate } = row.original.claimedStatus || {}
        if (elementMap) {
          const isOwner = (elementMap.element1Id =
            filters.element1 &&
            elementMap.element2Id === filters.element2 &&
            elementMap.element3Id === filters.element3 &&
            elementMap.element4Id === filters.element4)

          if (isOwner) {
            return (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    <div className="resource-popup">
                      <div className="text-start mb-1">
                        <h6>Claimed On</h6>
                        {moment(startDate).format('MMM DD, YYYY')}
                      </div>
                      <div className="text-start mb-1">
                        <h6>Claimed by</h6>
                        {claimedBy}
                      </div>
                      <div className="text-start">
                        <h6>Type</h6>
                        Resource
                      </div>
                    </div>
                  </Tooltip>
                )}
              >
                <span className="badge badge-claimed-owner">Claimed</span>
              </OverlayTrigger>
            )
          }
          return (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  <div className="resource-popup">
                    <div className="text-start mb-1">
                      <h6>{sorElementMapping.element4}</h6>
                      {elementMap.element4Id}
                    </div>
                    <div className="text-start mb-1">
                      <h6>Claimed by</h6>
                      {claimedBy}
                    </div>
                    <div className="text-start mb-1">
                      <h6>Claimed On</h6>
                      {moment(startDate).format('MMM DD, YYYY')}
                    </div>
                    <div className="text-start">
                      <h6>Type</h6>
                      Resource
                    </div>
                  </div>
                </Tooltip>
              )}
            >
              <span className="badge badge-claimed">Claimed</span>
            </OverlayTrigger>
          )
        }
        return <span className="badge badge-open">Open</span>
      },
      enableSorting: false
    }),
    columnHelper.accessor('productCode', {
      header: () => 'Service Name'
    }),
    columnHelper.accessor('resourceId', {
      header: () => 'Resource Name',
      cell: (info) => {
        const resourceName = info.getValue()
        return (
          <div style={{ maxWidth: '250px' }}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {resourceName}
                </Tooltip>
              )}
            >
              <div className="resource-cell">{resourceName}</div>
            </OverlayTrigger>
          </div>
        )
      }
    }),
    columnHelper.accessor('workload', {
      header: () => {
        return 'Workload'
      },
      cell: ({ row }) => {
        const { claimedStatus } = row.original
        if (claimedStatus?.elementMap || isReadOnly) {
          return <span>{claimedStatus?.reference}</span>
        }
        return (
          <Select
            classNamePrefix="select"
            options={workloadOptions}
            defaultValue={workloadOptions.find((option) => option.value === row.original.claimedStatus?.reference)}
            onChange={(option) => {
              row.original.claimedStatus = {
                ...row.original.claimedStatus,
                reference: option.value
              }
            }}
          />
        )
      },
      enableSorting: false
    }),
    columnHelper.accessor('tags', {
      header: () => 'Tags',
      cell: ({ row }) => {
        const tags = row.original.tags.map((item) => item.value)
        return tags.length > 0 ? (
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <Tooltip id="button-tooltip" {...props}>
                {tags.join(', ')}
              </Tooltip>
            )}
          >
            <div className="link-primary text-center">View</div>
          </OverlayTrigger>
        ) : (
          <div className="text-center">No Tags</div>
        )
      }
    })
  ]
}

export default getResourceColumns
